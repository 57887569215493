import React, { MutableRefObject } from "react";
import classes from "./FormItems.module.css";

interface FormInputProps {
  placeholder?: string;
  type?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  ref?: MutableRefObject<HTMLInputElement | null>;
  name?: string;
  style?: any;
  defaultValue?: string;
}

const FormInput = (props: FormInputProps) => {
  return (
    <div className={classes.formcontrol} {...props}>
      <input
        type={props.type}
        id={props.id}
        placeholder={props.placeholder}
        required={props.required}
        ref={props.ref}
        name={props.name}
        style={props.style}
        defaultValue={props.defaultValue}
      />
    </div>
  );
};

export default FormInput;
