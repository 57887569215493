import React, { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import FormInput from "../components/Forms/FormInput";
import FormButton from "../components/Forms/FormButton";
import FormErrors from "../components/Forms/FormErrors";
import LoginValidation from "../components/Forms/LoginValidation";
import { signInAccount, resendVerificationEmail } from "../helpers/api-utils";
// import { GoogleIcon } from "../components/ui/icons";
import classes from "../styles/login.module.css";
import { useStore } from "../context/store";
import Notification from "../components/ui/Notification";
import NavigationMenu from "~src/components/layout/NavigationMenu";
import { useIntl } from "react-intl";

const Login = () => {
    const router = useRouter();
    const { locale } = useRouter();
    const intl = useIntl();
    const sign_in = intl.formatMessage({ id: "login.signin"})
    const email_address = intl.formatMessage({ id: "login.email-address"})
    const password_text = intl.formatMessage({ id: "login.password"})
    const create_account = intl.formatMessage({ id: "login.create-account"}, {symbol: ">"})
    const forgot_password = intl.formatMessage({ id: "login.forgot-password"})
    const keepme_signedin = intl.formatMessage({ id: "login.keepme-signedin"})

    const { ApiStore } = useStore();
    const [notification, setnotification] = useState({ open: false, loading: false, message: "", error: false });

    const [resendEmailverifyBtn, setResendVerifyBtn] = useState(false);
    const [emailInput, setEmailInput] = useState("")
    const [formError, setFormError] = useState({
        email: "",
        password: "",
    });
    const [rememberMe, setRememberMe] = useState<boolean>();
    const [rememberMeUserId, setRememberMeUserId] = useState('')

    const [show, setShow] = useState(false)

    useEffect(() => {
        if(typeof window !== 'undefined'){
            const reMe = JSON.parse(localStorage.getItem('rememberMe')) === true
            const storedUserId = reMe ? JSON.parse(window.localStorage.getItem('stored_userid')) : ''
            setRememberMe(reMe)
            setRememberMeUserId(storedUserId)
            
        }
    }, [])

    useEffect(() => {
        const timeId = setTimeout(() => {
            // After 1 seconds set the show value to false
            if (show === true) {
                router.push(router.query.referer?.toString() || "/")
            }

        }, 1000)

        return () => {
            clearTimeout(timeId)
        }
    }, [show, router]);

    const resendVerificationEmailHandler = async () => {
        try {
            const result = await resendVerificationEmail(emailInput);
            console.log("result", result);
            if (result.error !== null) {
                setnotification({ open: true, loading: false, message: result.error.message, error: true });
            } else if (result.error === null) {
                setnotification({ open: true, loading: false, message: result.message, error: false })
                setResendVerifyBtn(false)
            }
        } catch (error) {
            console.error(error);
            setnotification({ open: true, loading: false, message: error.message, error: true });
        }
    }

    const formSubmitHandler = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            email: { value: string };
            password: { value: string };
        };
        const email = target.email.value; // typechecks!
        const password = target.password.value; // typechecks!

        const { errors, condition } = LoginValidation({
            email: email,
            password: password,
        });

        if (condition === false) {
            setFormError(errors);
            return;
        } else if (condition === true) {
            setFormError(errors);
        }

        try {
            const result = await signInAccount(email, password);
            console.log("result", result);
            if (result.error !== null && result.data === null) {
                if (result.error?.message) {
                    setnotification({ open: true, loading: false, message: result.error.message, error: true })
                } else if (result.error) {
                    setnotification({ open: true, loading: false, message: result.error, error: true })
                }

            } else if (result.error !== null) {
                console.log("Error: ", result);
                setnotification({ open: true, loading: false, message: result.error.message, error: true })
                if (result.error.message === "Account not verified!") {
                    setResendVerifyBtn(true);
                } else {
                    setResendVerifyBtn(false);
                }
            } else if (result.error === null) {
                setResendVerifyBtn(false);
                if (typeof window !== "undefined") {
                    window.localStorage.setItem(
                        "sketshare_user",
                        JSON.stringify(result.data)
                    );
                    window.localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
                    if(rememberMe === true){
                        window.localStorage.setItem('stored_userid', JSON.stringify(email))
                    }else if(rememberMe === false){
                        window.localStorage.setItem('stored_userid', JSON.stringify(''))
                    }
                }
                ApiStore.setLoginStatus(true);
                ApiStore.setToken(result.data.token);
                ApiStore.setUserData(result.data.user);
                setnotification({ open: true, loading: false, message: result.message, error: false })
                setShow(true)
            }
        } catch (error) {
            console.error(error);
            setnotification({ open: true, loading: false, message: error.message, error: true })
        }
    };

    return (
        <>
            <NavigationMenu />
            <div className={classes.auth_section}>
                <Notification notification={notification} setnotification={setnotification} error={notification.error} />
                <div className={classes.container}>
                    <div className={classes.login_area}>
                        <div 
                            className={classes.row}
                            style={{flexDirection: locale === 'ar' ? 'row-reverse' : 'row', textAlign: locale === 'ar' ? 'end' : 'start'}}
                        >
                            <div className={classes.col6_lg6}>
                                <form onSubmit={formSubmitHandler}
                                    style={{
                                        maxWidth: locale === 'ar' ? '100%' : '400px',
                                        textAlign: locale === 'ar' ? 'end' : 'start'
                                    }}
                                >
                                    <h2>{sign_in}</h2>
                                    <button
                                        type="button"
                                        className={classes.toggle}
                                        onClick={() => router.push("/signup")}
                                        style={{textAlign: locale === 'ar' ? 'end' : 'start'}}
                                    >
                                        {create_account}
                                    </button>
                                    <div className={classes.control} style={{textAlign: locale === 'ar' ? 'end' : 'start'}}>
                                        <FormInput
                                            type="email"
                                            placeholder={email_address}
                                            style={{textAlign: locale === 'ar' ? 'end' : 'start'}}
                                            name="email"
                                            defaultValue={rememberMeUserId}
                                            onChange={(e) => setEmailInput(e.target.value)}
                                        />
                                    </div>
                                    <FormErrors error={formError.email} />
                                    <div className={classes.control}>
                                        <FormInput
                                            type="password"
                                            placeholder={password_text}
                                            style={{textAlign: locale === 'ar' ? 'end' : 'start'}}
                                            name="password"
                                        />
                                    </div>
                                    <FormErrors error={formError.password} />
                                    <div className={classes.forgot_password} style={{flexDirection: locale === 'ar' ? 'row-reverse' : 'row'}}>
                                        <Link href="/recover" passHref={true}>
                                            <p>{forgot_password}</p>
                                        </Link>
                                        {resendEmailverifyBtn &&
                                            <button type="button" onClick={resendVerificationEmailHandler}>Resend verification email</button>
                                        }
                                    </div>
                                    <div className={classes.email_checkbox_container}
                                        style={{
                                            display: 'flex', 
                                            flexDirection: locale === 'ar' ? 'row-reverse' : 'row'
                                        }}
                                    >
                                        <input 
                                            className={classes.email_checkbox} 
                                            type="checkbox"
                                            checked={rememberMe}
                                            onChange={(e: any) => setRememberMe(e.target.checked)} 
                                        />
                                        <label 
                                            htmlFor="email_checkbox"
                                            style={{
                                                marginRight: locale === 'ar' ? '8px' : '0',
                                                marginLeft: locale !== 'ar' ? '8px' : '0'
                                            }}
                                        >
                                            {keepme_signedin}
                                        </label>
                                    </div>
                                    <div className={classes.actions}
                                        style={{alignItems: locale === 'ar' ? 'end' : 'start'}}
                                    >
                                        <FormButton title={sign_in} />
                                    </div>
                                </form>
                            </div>
                            <div className={classes.col6_lg6}>
                                <div className={classes.login_image}>
                                    <Image
                                        src="https://img.freepik.com/free-vector/access-control-system-abstract-concept_335657-3180.jpg"
                                        alt="signin"
                                        width="600px"
                                        height="510px"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
