import React from 'react';
import classes from './FormItems.module.css';

interface FormButtonProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
    onSubmit?: React.FormEventHandler
    type?: string
    title?: string
}

const FormButton = (props: FormButtonProps) => {

    return (
        <button
            type='submit'
            className={classes.toggle}
            onClick={props.onClick}
            onSubmit={props.onSubmit}
        >
            {props.title}
        </button>
    )
}

export default FormButton