const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\.\+\*\{\]\{\[\-,;`<>':"=^#_|\/\\])(?=.*?[A-Za-z\d@$!%*+?&\.\+\*\{\]\{\[\-,;`<>':"=^#_|\/\\]).{8,}/;
const EMAIL_REGEX =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
export default function LoginValidation(inputs: any) {
  let errors = {
    email: "",
    password: "",
  };
  let condition = true;

  if (!inputs.email) {
    errors.email = "Email address is required";
    condition = false;
  } else if (!EMAIL_REGEX.test(inputs.email)) {
    errors.email = "Email address is invalid";
    condition = false;
  }
  if (!inputs.password) {
    errors.password = "Password is required";
    condition = false;
  } else if (!PASSWORD_REGEX.test(inputs.password)) {
    errors.password =
      "Min 8 characters with atleast one capital letter, number & symbol";
    condition = false;
  }
  return { errors, condition };
}
